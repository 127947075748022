export const _1_HOUR_IN_MILLIS = 3600000;
export const _24_HOURS_IN_MILLIS = 86400000;
export const ANIM_ICON_CONTAINER_CSS_ID = 'iconContainer';
export const CLASSROOM_MAX_DUEL_COUNT = 10;
export const DEFAULT_DUEL_DURATION_HOURS = 18;
export const DEFAULT_SNACK_DURATION_MS = 5000;
export const DEFAULT_STUDY_BEGIN_ID = '3c28e08d-d20e-431c-8d48-d1c75d596e88';
export const DT_LEARNING_YEAR_IN_DAYS = 360;
export const DUEL_LAST_QUESTION_NUMBER = 3;
export const FREEMIUM_MAX_DUEL_COUNT = 5;
export const HEIGHT_TOPBAR_PX = 56;
export const LS_KEY_AUTH = 'auth';
export const LS_KEY_CLICKED_DATE = 'clickedDate';
export const LS_KEY_IS_PAY_CONF_ACTIVE = 'isConfirmationActive';
export const LS_KEY_PAY_START_PREMIUM_PRESSED = 'startPremiumPressed';
export const LS_KEY_LAST_ONBOARD_PAGE = 'lastOnboardPage';
export const LS_KEY_TABINDEX = '_tabIndex';
export const LS_KEY_IN_APP_SUBS_CANCELING = 'inAppSubsCanceling';
export const LS_KEY_NATIVE_OS_NAME = 'nativeOSName';
export const MAIN_ORGANIZER_NAME = 'Humeo';
export const MAX_AVATAR_SIZE_MB = 5;
export const MAX_DT_WEEKLY_GOAL = 7;
export const MAX_REG_AGE = 70;
export const MEMBER_LIST_ITEM_CSS_ID = 'myMemberRanglist';
export const MIN_REG_AGE = 18;
export const OTHER_WORKPLACE = '00000000-0000-0000-0000-000000000000';
export const PAY_PROCESSING_MAX_AGE_MS = 15000;
export const PAY_PROCESSING_POLL_INTERVAL = 5000;
export const POSTAL_CODE_GERMANY = 'DE';
export const PREMIUM_MAX_DUEL_COUNT = 10;
export const QT_RANKING_REFRESH_INTERVAL_MS = 60000;
export const QT_TIME_TO_FINAL_RANKING_HOURS = 2;
export const QUIZTIME_MAX_DUEL_COUNT = 5;
export const REPORT_EMAIL = 'hilfe@humeo.de';
export const SS_KEY_MEMO_CUR_TAB = 'shouldMemorizeCurrentTab';
export const SS_KEY_REG_DATA = 'REG_DATA';
export const SS_KEY_STEP_SIGNUP = 'STEP_SIGNUP';
export const TIMER_REFRESH_CYCLE = 1000;
export const USERCENTRICS_SCRIPT_ID = 'usercentrics-cmp';
export const USERCENTRICS_SCRIPT_SRC =
  'https://app.usercentrics.eu/browser-ui/latest/loader.js';
export const USERCENTRICS_SETTINGS_ID = 'sZxtVx6-C';
export const WEEK_IN_MS = 604800000;
