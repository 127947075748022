import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DoctorSrc from '../../../images/doctor.png';
import { useStyles } from './styles';

const AuthIntro: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box component="section" textAlign="center" display="flex" flexDirection="column" alignItems="center" mt={12}>
      <img src={DoctorSrc} alt="Humeo" className={classes.pic} />
      <Box mt={7}>
        <Typography variant="h1" color='primary' className={classes.title}>{t(`onboarding.auth-intro.title`)}</Typography>
        <Typography variant="body1">
          {t(`onboarding.auth-intro.description`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthIntro;
